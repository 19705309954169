@import "~bootstrap/scss/bootstrap";

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone-active {
  border-color: #2196f3;
}

.dropzone-accept {
  border-color: #00e676;
}

.dropzone-reject {
  border-color: #ff1744;
}

.table-responsive {
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 1rem;
}
